<template>
  <div class="leaderinfo">
    <full-scroll></full-scroll>
    <div class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            ">
      <div class="flex flex-pack-center flex-1">
        <img src="@/assets/national_emblem.png" alt="" class="msg-national m_r20" />
        <div class="text_align_last">
          <p class="font-size24 m_b10">广阳镇人大代表之家</p>
          <p class="font-size12">
            GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
          </p>
        </div>
      </div>
      <div class="msg-title-mid-box font-size32 font-blod flex-1">
        <img src="@/assets/images/tot-top-title.png" alt="" />
        <div class="msg-title-mid-box-title">代表信息</div>
      </div>
      <div class="flex-end flex-1 flex" @click="backHome">
        <div class="my_text_center">
          <img src="@/assets/images/backhome-new.png" alt="" class="msg-backhome m_b10" />
          <p class="font-size16">返回首页</p>
        </div>
      </div>
    </div>

    <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">
      <div class="feedback-box flex flex-justify-between">
        <div class="feedback-left">
          <div class="
                            font-size28 font-blod
                            feedback-left-tot-title
                            color_FF
                            my_text_center
                            m_b10
                        ">
            代表信息
          </div>
          <div class="font-size24 feedback-left-tot-box">
            <van-collapse v-model="activeNames" accordion @change="changeACtiveName">
              <van-collapse-item :title="item.name" :name="item.index"
                v-for="(item, id) in leaderTypeList" :key="id">
                <ul class="">
                  <li :class="isActived == index ? 'actived' : ''
                                        " v-for="(nitem, index) in leaderTypeList[
                                            id
                                        ].child" :key="index" @click="
                                            goToLeaderMlist(nitem.id,nitem.name, index)
                                            ">
                    <span></span>{{ nitem.name }}
                  </li>
                </ul>
              </van-collapse-item>
            </van-collapse>
          </div>
        </div>
        <div class="feedback-right">
          <div class="
                            feedback-right-title
                            font-size24 font-blod
                            my_text_center
                            color_FF
                        ">
            {{ isShowList == 1 ? "代表信息" : isShowList == 2 ? "接访群众预安排" : isShowList == 3 ? "代表详情" : ""
            }}
          </div>
          <div class="feedback-right-box">
            <div class="feedbacks" v-if="isShowList == 1">
              <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <ul class="color_FF" id="notice-box-list">
                  <li class="my_text_center" v-for="(item, id) in leaderList" :key="id"
                    @click="goToLeaderInfo(item)">
                    <img :src="item.avatar" alt="" class="m_b20" />
                    <p class="m_b10 font-size16">
                      {{ item.name }}
                    </p>
                    <p class="font-size16 m_b10">
                      单位：{{ item.unit }}
                    </p>
                    <p class="font-size16 m_b10">
                      职务：{{ item.duties }}
                    </p>
                  </li>
                </ul>
              </van-list>
            </div>

            <div class="notice-box" v-if="isShowList == 2">
              <div class="notice-box-list" id="notice-box-list-two">
                <ul>
                  <van-list v-model="loading" :finished="finished" finished-text="没有更多了"
                    @load="onLoad">
                    <li class="notice-list flex" v-for="(item, id) in leaderList" :key="id" @click="goToDetail(item)">
                      <div class="m_r20" v-if="item.cover != null && item.cover != ''">
                        <img :src="item.cover" alt="" />
                      </div>
                      <div class="
                                        flex-1
                                        m_r20
                                        flex flex-v flex-justify-center
                                    ">
                        <div class="notice-list-box color_FF">
                          <p class="font-size24 m_b20">
                            {{ item.title }}
                          </p>
                          <p class="font-size22 color_9FB">
                            {{ item.releaseTime }}
                          </p>
                        </div>
                      </div>
                      <div class="
                                        check-info
                                        flex
                                        flex-justify-center
                                        flex-pack-center
                                    ">

                        <span class="font-size18 color_FF" @click.stop="goToDetail(item)">查看详情</span>
                      </div>
                    </li>
                  </van-list>
                </ul>
              </div>
            </div>

            <div v-if="isShowList == 3">
              <div class="flex flex-pack-center return" @click="gotoreturn">
                <img src="@/assets/images/return.png" alt="" class="img">
                <div class=" color_FF">
                  返回
                </div>
              </div>
              <div class="leader-desc my_text_center color_FF">

                <div class="m_b30"></div>
                <img :src="leaderInfo.avatar" alt="" class="m_b10" />
                <p class="font-size24 font-blod m_b30" v-if="leaderInfo.name != null">
                  {{ leaderInfo.name }}
                </p>
                <p class="font-size18 m_b10" v-if="leaderInfo.duties != null">
                  单位：{{ leaderInfo.unit }}
                </p>
                <p class="font-size18 m_b10" v-if="leaderInfo.duties != null">
                  职务：{{ leaderInfo.duties }}
                </p>
                <p class="font-size18" v-if="leaderInfo.contactNum != null">
                  联系电话：{{ leaderInfo.contactNum }}
                </p>

                <div v-if="leaderInfo.description != ''">
                  <van-divider :style="{
                                        color: '#FFF',
                                        padding: '10px 60px',
                                    }">个人简介
                  </van-divider>
                  <p class="leader-desc-info font-size18">
                    {{ leaderInfo.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            ">
      <div class="my_text_center" @click="goBack">
        <img src="@/assets/images/return-new.png" alt="" class="msg-return m_b10" />
        <p class="font-size24 color_FF">返回上级</p>
      </div>
      <div class="flex ">
        <div class="complaint-1 flex flex-justify-center flex-pack-center" @click="gotama" v-if="Login">
          代表接访平台
        </div>
        <div class="complaint-2 flex flex-justify-center flex-pack-center" v-if="Login">
          代表信息
        </div>
      </div>

      <div class="my_text_center color_FF">
        <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
        <p class="font-size14">{{ todayTime }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { dateTimeFormat } from "@/request/until.js";
import { delegateList, delegateTypeList, prearrangementlist } from "@/request/api.js";
import fullScroll from "@/components/fullScroll.vue";
import { Toast } from "vant";

export default {
  components: { fullScroll },
  data () {
    return {
      scrollTop: 0,
      showDoc: false,
      prearrangementData: [],//预安排的详情
      Login: false,
      result: {},
      tid: 0,
      pageNum: 1,
      pageSize: 15,
      totalPage: null,
      isActived: 0,
      loading: false,
      finished: false,
      leaderTypeList: [],
      leaderList: [],
      leaderInfo: null,
      todayMtime: "",
      todayTime: "",
      activeNames: 0,
      isShowList: 1,
      type: null
    };
  },
  created () {
    this.getTime();
  },
  mounted () {
    this.getLeaderType();
    this.timer = setInterval(() => {
      this.getTime();
    }, 1000);
    if (sessionStorage.getItem("Authorization")) {
      this.Login = true;
    }
  },

  activated () {
    if (this.isShowList !== 2) {
      document.getElementById("notice-box-list").scrollTop = this.scrollTop; // 设置滚动条位置
    } else {
      document.getElementById("notice-box-list-two").scrollTop = this.scrollTop; // 设置滚动条位置
    }
  },
  methods: {
    onLoad () {
      if (this.totalPage <= this.pageNum) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = true;
        this.pageNum++;
        if (this.isShowList === 2) {
          this.prearrangementlistData();
        } else {
          this.getList();
        }
      }
    },

    getTime () {
      let _this = this;
      let time = "";
      time = dateTimeFormat(new Date());
      _this.todayTime = time.split(" ")[0];
      _this.todayMtime = time.split(" ")[1].slice(0, 5);
    },

    getLeaderType () {
      delegateTypeList().then((res) => {
        res.data.forEach((item, index) => {
          item.child.push({
            id: item.id,
            name: "接访群众预安排"
          });
          item.child.forEach((childItem) => {
            if (!this.result[index]) {
              this.result[index] = [];
            }
            this.result[index].push({
              id: childItem.id
            });
          });
        });
        this.leaderTypeList = res.data;
        this.type = res.data[0].child[0].id;
        this.getList();
      });
    },

    goToDetail (data) {
      this.scrollTop = document.getElementById("notice-box-list-two").scrollTop;
      let Data = JSON.stringify(data);
      this.$router.push({
        name: "supervisedetail",
        params: { data: encodeURIComponent(Data), type: 7 }
      });
    },

    getList () {
      delegateList({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        status: 1,
        type: this.type
      }).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          let data = res.data.list;
          if (data.length == 0) {
            this.finished = true;
          } else {
            if (this.pageNum == res.data.totalPage) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.totalPage = res.data.totalPage;
            this.leaderList.push(...data);
          }
        }
      });
    },

    /**
     * 接访群众预安排-分页列表
     */
    prearrangementlistData () {
      prearrangementlist({
        tid: this.tid,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        this.loading = false;
        if (res.code == 200) {
          let data = res.data.list;
          if (data.length == 0) {
            this.finished = true;
          } else {
            if (this.pageNum == res.data.totalPage) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.totalPage = res.data.totalPage;
            this.leaderList.push(...data);
          }
        }
      });
    },

    goToLeaderInfo (data) {
      this.scrollTop = document.getElementById("notice-box-list").scrollTop;
      this.isShowList = 3;
      this.leaderInfo = data;
    },

    /**
     * 二级联动
     */
    goToLeaderMlist (id, name, index) {
      this.leaderList = [];
      this.pageNum = 1;
      this.type = id;
      this.isActived = index;
      if (name === "接访群众预安排") {
        this.isShowList = 2;
        this.tid = id;
        this.prearrangementlistData();
      } else {
        this.getList();
        this.isShowList = 1;
      }
    },

    /**
     * 一级联动
     */
    changeACtiveName (data) {
      if (data >= 0) {
        this.pageNum = 1;
        this.isActived = 0,
          this.isShowList = 1;
        this.leaderList = [];
        this.type = this.result[data][0].id;
        this.getList();
      }

    },
    //返回首页
    backHome () {
      this.$router.push("/");
    },
    goBack () {
      this.$router.back();
    },

    gotama () {
      if (!sessionStorage.getItem("Authorization")) {
        Toast.fail("请先登录");
        this.$router.push("/");
      } else {
        this.$router.push("/behalfFeedback");
      }
    },

    gotoreturn () {
      this.isShowList = 1;
    },

    //预览附件
    preview () {
      this.showDoc = true;
    }
  },

  beforeRouteLeave (to, from, next) {
    if (to.path === "/supervisedetail") {
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
    }
    next();
  }
};
</script>
